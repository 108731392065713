import base from './base'
import axios  from 'axios'

const http ={
    getMeal:function(key:string){
        return axios.get(base.getMeal+'?csuKey='+key)
    },
    surCostEdit:function(params:object){
        return axios.post(base.surCostEdit,params)
    },
    survey:function (key:string){
        return axios.get(base.survey+"?cusKey="+key);
    },
    getCsuId:function (key:string){
        return axios.get(base.getCsuId+"?csuKey="+key);
    },
    uploadPdf: function (file: File, key: string,csuId:number) {
        const formData = new FormData();
        formData.append("pdfFile", file);
        return axios.post(base.uploadPdf + "?cusKey=" + key+"&csuId="+csuId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getTextFont:function (key:string){
        return axios.get(base.getTextFont+"?csuKey="+key);
    },


}

export default http
